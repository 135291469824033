import React, {useState} from 'react';
import {Accordion, Button, Card, Col, Form, Modal, Row, useAccordionButton} from "react-bootstrap";
import ReactQuill from "react-quill";
import {addMatrica, getMatrica} from "../../http/getApi";
import {toast} from "react-toastify";
import EditMatrica from "./EditMatrica";
import Search from "./block/Search";

function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionButton(eventKey);

	return (
		<Button
		variant="success"
		className="me-2"
			onClick={decoratedOnClick}
		>
			{children}
		</Button>
	);
}

const Matrica = () => {


	const [name, setName] = useState()
	const [text, setText] = useState()
	const [keyId, setKeyId] = useState(0)
	const [keyId2, setKeyId2] = useState(0)
	const [keyId3, setKeyId3] = useState(0)
	const [show, setShow] = useState(false)
	const [show2, setShow2] = useState(false)
	const [show3, setShow3] = useState(false)
	const [matrica, setMatrica] = useState([])
	const [matricaId, setMatricaId] = useState(0)


	const saveMatrica = () => {

		const formData = new FormData()
		formData.append('text', text)
		formData.append('name', name )
		formData.append('keyid1', keyId )
		formData.append('keyid2', keyId2 )
		formData.append('keyid3', keyId3 )

		addMatrica(formData).then(data=> {
			if(data.status){
				toast.success(data.message)
				setKeyId(0)
				setKeyId2(0)
				setKeyId3(0)
				setText('')
				setName('')
			}
		})
	}

	const  delS = (mat) => {
		setMatrica(matrica.filter(f => f.id !== mat.id))
	}

	const UnsafeComponent =({ html }) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	}

	const search = () => {
		getMatrica(keyId,keyId2, keyId3).then(data => {
			if(data.status){
				toast.success("Совподения найдены")
				setMatrica([...matrica, ...data.matrica])
			}else{
				toast.error("ничего не найдено")
			}
		})
	}

	return (
		<div>
			<Button variant="outline-light" className="mb-3"
							onClick={() => setShow(true)}
			>Добавить</Button>

			<Button variant="outline-light"
							className="mb-3 ms-4"
							onClick={() => setShow3(true)}
			><i className="bi-search"/> Поиск</Button>

			<div className="bg-light p-4 rounded-4">
				<div className="d-flex justify-content-between">
					<div>
						<Form.Control type="number" value={keyId} onChange={(e) => setKeyId(e.target.value)}/>
					</div>
					<div className="text-center">-</div>
					<div><Form.Control type="number" value={keyId2} onChange={(e) => setKeyId2(e.target.value)}/></div>
					<div className="text-center">-</div>
					<div><Form.Control type="number" value={keyId3} onChange={(e) => setKeyId3(e.target.value)}/></div>
					<div className="text-center">
						<Button onClick={search} variant="warning">Поиск</Button>
					</div>
					<div className="text-center">
						<Button onClick={()=>setMatrica([])} variant="danger">Очистить</Button>
					</div>
				</div>
			</div>
			<div>

				{matrica.length > 0 &&
					<Accordion className="my-4" >
						{matrica.map((mat, index) =>
							<Card key={index}>
								<Card.Header>
									<div className="d-flex justify-content-between">
										<div>
											<CustomToggle eventKey={index}><i className="bi-arrow-down-short"></i> </CustomToggle>
										</div>
										<div className="flex-grow-1">
											{mat.name} ({mat.keyid1}-{mat.keyid2}-{mat.keyid3})
										</div>
										<div className="text-end">
											<Button className="me-2" variant="danger" onClick={() => delS(mat)}><i className="bi-trash"></i> </Button>
										</div>
									</div>

								</Card.Header>
								<Accordion.Collapse eventKey={index}>
									<Card.Body>
										<UnsafeComponent html={mat.text} />
										<Button variant="success" onClick={()=>{
											setMatricaId(index)
											setShow2(true)

										}}>Редактировать</Button>
									</Card.Body>

								</Accordion.Collapse>
							</Card>


				)}
					</Accordion>
				}


			</div>

			<Modal size="lg" show={show} onHide={() => setShow(false)} animation={false} className="text-black">
				<Modal.Header closeButton>
					<Modal.Title>Добавить</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Название</Form.Label>
						<Form.Control type="text" placeholder="Имя ключа"
													value={name}
													onChange={(e) => setName(e.target.value)}
						/>
					</Form.Group>
					<Row className="my-5">
						<Col>
							<Form.Control type="number" value={keyId} onChange={(e) => setKeyId(e.target.value)}/>
						</Col>
						<Col className="text-center">-</Col>
						<Col><Form.Control type="number" value={keyId2} onChange={(e) => setKeyId2(e.target.value)}/></Col>
						<Col className="text-center">-</Col>
						<Col><Form.Control type="number" value={keyId3} onChange={(e) => setKeyId3(e.target.value)}/></Col>
					</Row>


					<ReactQuill
						value={text}
						onChange={(value) => setText(value)}
						modules={{
							toolbar: [
								['bold', 'italic', 'underline', 'strike'],        // toggled buttons
								['blockquote', 'code-block'],

								[{'header': 1}, {'header': 2}],               // custom button values
								[{'list': 'ordered'}, {'list': 'bullet'}],
								[{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
								[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
								[{'direction': 'rtl'}],                         // text direction

								[{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
								[{'header': [1, 2, 3, 4, 5, 6, false]}],

								[{'color': []}, {'background': []}],          // dropdown with defaults from theme
								[{'font': []}],
								[{'align': []}],

								['clean']
							]
						}}
						theme="snow"
					/>
				</Modal.Body>
				<Modal.Footer>

					<Button variant="primary" onClick={saveMatrica}>
						Сохранить
					</Button>
				</Modal.Footer>


			</Modal>

			<Modal size="lg" show={show2} onHide={() => setShow2(false)} animation={false} className="text-black">
				<Modal.Header closeButton>
					<Modal.Title>Редактирование</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<EditMatrica matricaO={matrica} setMatricaO={setMatrica} id={matricaId} />
				</Modal.Body>
			</Modal>

			<Modal  fullscreen={true} show={show3} onHide={() => setShow3(false)} animation={true} className="text-black">
				<Modal.Header closeButton>
					<Modal.Title>Поиск по заголовку</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Search setMatrica={setMatrica} matrica={matrica}/>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default Matrica;