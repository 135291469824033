import React, {useState} from 'react';
import {Accordion, Button, Card, Form, ListGroup, useAccordionButton} from "react-bootstrap";
import {getSearch} from "../../../http/getApi";
function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionButton(eventKey);

	return (
		<Button
			variant="success"
			className="me-2"
			onClick={decoratedOnClick}
		>
			{children}
		</Button>
	);
}
const Search = ({setMatrica,matrica}) => {
	const [search, setSearch] = useState('')
	const [result, setResult] = useState(null)

	const searchFunc = event => {

		if (event.keyCode === 13) {
			getSearch(search).then(data=>{
				if(data.status){
					setResult(data.itogo)
				}else{
					setResult(null)
				}
			})
		}
	}

	const searchFuncB = event => {
			getSearch(search).then(data=>{
				if(data.status){
					setResult(data.itogo)
				}else{
					setResult(null)
				}
			})
	}

	const  addM = (index) =>{

		//console.log(result[index])
		if(matrica.length > 0){
			setMatrica([...matrica, result[index]])
		}else{
			setMatrica([result[index]])
		}


	}

	const UnsafeComponent =({ html }) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	}

	return (
		<div>
			<div className="input-group">

					<Form.Control
						value={search}
						onKeyUp={searchFunc}
						onChange={(e)=>{setSearch(e.target.value)}}
					/>
					<Button
				onClick={searchFuncB}
					>Искать</Button>

			</div>

			<div className="py-4">

				<Accordion className="my-4" >
					{result && result.map((res, index) =>
						<Card key={index}>
							<Card.Header>
								<div className="d-flex justify-content-between">
									<div>
										<CustomToggle eventKey={index}><i className="bi-arrow-down-short"></i> </CustomToggle>
									</div>
									<div className="flex-grow-1">
										{res.name} ({res.keyid1}-{res.keyid2}-{res.keyid3})
									</div>
									<div className="text-end">
										<Button onClick={()=>addM(index)} variant="success" size="sm"><i className="bi-plus"/> </Button>
									</div>
								</div>

							</Card.Header>
							<Accordion.Collapse eventKey={index}>
								<Card.Body>
									<UnsafeComponent html={res.text} />

								</Card.Body>

							</Accordion.Collapse>
						</Card>


					)}
				</Accordion>



			</div>

		</div>
	);
};

export default Search;