import React from 'react';
import {Button, Col, Row} from "react-bootstrap";

const Clicker = ({col,row,setCol,setRow}) => {
	return (
		<Row className="m-4">
			<Col> <span className="me-3">строчки </span>
				<Button variant="primary"
								size="sm"
								onClick={()=> {
									if(row >= 2) {
										setRow(row - 1)
									}
								}}>-</Button>
				<span style={{fontSize:"1.4rem"}} className="m-2">{row}</span>
				<Button variant="primary"
								size="sm"
								onClick={()=> {

									setRow(row + 1)

								}}>+</Button>
			</Col>

			<Col> <span className="me-3">Столбики</span>
				<Button variant="primary"
								size="sm"
								onClick={()=> {
									if(col >= 2) {
										setCol(col - 1)
									}
								}}>-</Button>
				<span style={{fontSize:"1.4rem"}} className="m-2">{col}</span>
				<Button variant="primary"
								size="sm"
								onClick={()=> {

									setCol(col + 1)

								}}>+</Button>
			</Col>
		</Row>
	);
};

export default Clicker;