import React, {useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Car from "../component/Car";
import Rasclad from "./block/main/Rasclad";


const Main = () => {



	return (
		<>

<div className="main">
	<div className="photo">
	</div>

	<Container className="mainc">
		<div className="mainText">
			<h1>Екатерина</h1>
			<p>Помогу в жизни</p>
			<div className="d-flex justify-content-end align-items-center">
				<div className="m-2">
					<Button target="_blank" href="https://t.me/leontyeva_ekaterina" variant="outline-light"><i className="bi bi-telegram"></i></Button>
				</div>
				<div className="m-2">
					<Button target="_blank" href="https://instagram.com/vision_taro?igshid=M2RkZGJiMzhjOQ%3D%3D&utm_source=qr" variant="outline-light"><i className="bi bi-instagram"></i></Button>
				</div>
			</div>
		</div>
	</Container>


</div>
	<Rasclad/>
		</>
	);
};

export default Main;