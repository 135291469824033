import React from 'react';


const Car = ({value}) => {
	return (
		<div className="cardV">
			<span>{value}</span>
		</div>
	);
};

export default Car;