import React from 'react';
import {Container, Nav, Navbar, NavbarCollapse, NavbarToggle, NavLink} from "react-bootstrap";

const NavBar = () => {
	return (
		<Navbar  data-bs-theme="dark" className="color1" expand="lg"  collapseOnSelect>
			<Container>
				<Navbar.Brand><NavLink to={"/"} className="nav-link">Vtaro</NavLink></Navbar.Brand>
				<NavbarToggle aria-controls="responsive-navbar-nav" />
				<NavbarCollapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<NavLink to={"/"} className="nav-link">Главная</NavLink>
					</Nav>
				</NavbarCollapse>
			</Container>
		</Navbar>
	);
};

export default NavBar;