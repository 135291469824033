import React from 'react';
import {Container} from "react-bootstrap";

const Footer = () => {
	return (
		<footer className="p-5 color1">
			<Container>
				<p className="text-center text-white">© Раскладик 2023</p>
			</Container>
		</footer>
	);
};

export default Footer;