import React from 'react';
import {Container} from "react-bootstrap";

const Admin = ({children}) => {
	return (
		<Container fluid="lg">
			<div><h1>Админ панель</h1></div>
			{children}
		</Container>
	);
};

export default Admin;