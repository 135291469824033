import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Car from "../../../component/Car";
import {getRasclad} from "../../../http/getApi";
import {logDOM} from "@testing-library/react";

const Rasclad = () => {

	const [active, setActive] = useState(1)
	const [rasclad, setRasclad]  = useState(null)

	const getRasFunc = () => {
		getRasclad().then(data => {
			setRasclad(data)
			setActive(data[0].id)
		})
	}

	useEffect(() => {
		getRasFunc()
	}, []);
	const UnsafeComponent =({ html }) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	}

	return (
		<Container fluid="lg" >

			<h1>Виды раскладов</h1>


					{rasclad &&
						rasclad.map((d,index) =>
							<Button
								key={index}
								variant={active ===  d.id ?"primary": "light"}
								onClick={()=>setActive(d.id)}
								className="m-2"
							>{d.name}</Button>
						)}
						{rasclad &&
								rasclad.map(f=>
									<div  key={f.id} className={active ===  f.id ? "typRasclad d-block" : "d-none"}>
										<Row>


											<Col lg={7}>
											{f.danie && f.danie.map((m,index)=>
												<div key={index}>
													<Row>
														{m.map((column, index) =>
															<Col key={index}>
																{column > 0 && <Car value={column}/>}
															</Col>
														)}
													</Row>
												</div>
											)}
											</Col>
											<Col lg={5}>
												<div className="ql-editor d-flex align-items-center" style={{fontSize:'1.5rem'}}>
													<UnsafeComponent html={f.text} />
												</div>
											</Col>
										</Row>
									</div>
								)
						}







		</Container>
	);
};

export default Rasclad;