import React, {useEffect, useState} from 'react';
import {editMatrica, getMatricaID} from "../../http/getApi";
import {Button, Col, Form, Row} from "react-bootstrap";
import ReactQuill from "react-quill";
import {toast} from "react-toastify";


const EditMatrica = ({matricaO,id,setMatricaO}) => {

	const [matrica, setMatrica] = useState(null)
	const [text, setText] = useState('')
	const [name, setName] = useState('')
	const [keyId1, setKeyId1] = useState(0)
	const [keyId2, setKeyId2] = useState(0)
	const [keyId3, setKeyId3] = useState(0)

	const getMatricaFunc = () => {
		getMatricaID(id).then(data=>{
			if(data.status){
				setMatrica(data.matrica)
				setText(data.matrica.text)
			}
		})
	}

	const saveMatrica = () => {
		const updatedData = [...matricaO];
		updatedData[id].name = name;
		updatedData[id].keyid1 = keyId1;
		updatedData[id].keyid2 = keyId2;
		updatedData[id].keyid3 = keyId3;
		updatedData[id].text = text;
		setMatricaO(updatedData);

		const formData = new FormData()
		formData.append('text', text)
		formData.append('name', name )
		formData.append('keyid1', keyId1 )
		formData.append('keyid2', keyId2 )
		formData.append('keyid3', keyId3 )

		let idM = matricaO[id].id
		editMatrica(formData,idM).then(data=>{
			if(data.status){
				toast.success(data.message)
			}
		})


	}

	useEffect(() => {
		setText(matricaO[id].text)
		setName(matricaO[id].name)
		setKeyId1(matricaO[id].keyid1)
		setKeyId2(matricaO[id].keyid2)
		setKeyId3(matricaO[id].keyid3)
	}, [id]);








	return (
		<div>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Название</Form.Label>
				<Form.Control type="text" placeholder="Имя ключа"
											value={name}
											onChange={(e) => setName( e.target.value)}
				/>
			</Form.Group>
			<Row className="my-5">
				<Col>
					<Form.Control type="number" value={keyId1} onChange={(e) => setKeyId1(e.target.value)}/>
				</Col>
				<Col className="text-center">-</Col>
				<Col><Form.Control type="number" value={keyId2} onChange={(e) => setKeyId2(e.target.value)}/></Col>
				<Col className="text-center">-</Col>
				<Col><Form.Control type="number" value={keyId3} onChange={(e) => setKeyId3(e.target.value)}/></Col>
			</Row>


			<ReactQuill
				value={text}
				onChange={(value) => setText(value)}

				modules={{
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],        // toggled buttons
						['blockquote', 'code-block'],

						[{'header': 1}, {'header': 2}],               // custom button values
						[{'list': 'ordered'}, {'list': 'bullet'}],
						[{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
						[{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
						[{'direction': 'rtl'}],                         // text direction

						[{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
						[{'header': [1, 2, 3, 4, 5, 6, false]}],

						[{'color': []}, {'background': []}],          // dropdown with defaults from theme
						[{'font': []}],
						[{'align': []}],

						['clean']
					]
				}}
				theme="snow"
			/>

			<Button className="mt-3" onClick={saveMatrica} variant="success">Сохранить</Button>
		</div>
	);
};

export default EditMatrica;