import {$host} from "./index";

export const getRasclad = async  () => {
	const {data} = await $host.get('rasclad')
	return data
}

export const addRasclad = async (formData) => {
	const {data} = await $host.post(`rasclad`, formData)
	return data
}

export const delRasclad = async (id) => {
	const {data} = await $host.delete(`rasclad/${id}`)
	return data
}

export const addMatrica = async (formData) => {
	const {data} = await $host.post(`matrica`, formData)
	return data
}

export const getMatrica = async (k1,k2,k3) => {
	const {data} = await $host.get(`matrica?keyid1=${k1}&keyid2=${k2}&keyid3=${k3}`)
	return data
}

export const getMatricaID = async (id) => {
	const {data} = await $host.get(`matrica/${id}`)
	return data
}

export const editMatrica = async (formData,id) => {
	const {data} = await $host.post(`matrica/${id}`, formData)
	return data
}

export const getSearch = async (search) => {
	const {data} = await $host.get(`matrica-s/?search=${search}`)
	return data
}