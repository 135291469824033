import React, {useEffect, useState} from 'react';
import {addRasclad, delRasclad, getRasclad} from "../../http/getApi";
import {Button, Card, Col, Form, Modal, Row} from "react-bootstrap";

import Clicker from "./com/Clicker";
import {toast} from "react-toastify";



import ReactQuill from "react-quill";


const RascladEdit = () => {
	const [active, setActive] = useState(0)
	const [rasclad, setRasclad]  = useState(null)
	const [show, setShow] = useState(false)

	const [show2, setShow2] = useState(false)

	const [text, setText] = useState('')
	const [massive, setMassive] = useState([])


	const [row, setRow] = useState(3)
	const [col, setCol] = useState(2)
	const [name, setName] =  useState('')




	useEffect(() => {

		let array = null;
		array = Array(row).fill().map((x, i) => Array(col).fill(0) );
		setMassive(array)

	}, [row,col]);

	const funcIzm = (var1,var2,var3) => {
		const updatedMassive = [...massive];
		updatedMassive[var1][var2] = var3;

		setMassive(updatedMassive);

	}

	const getRasFunc = () => {
		getRasclad().then(data => {
			setRasclad(data)
		})
	}

	useEffect(() => {
		getRasFunc()
	}, []);


	const saveRasklad = (id) =>{

		const formData = new FormData()
		if(id){
			formData.append('id', id)
		}

		formData.append('text', text)
		formData.append('name', name )

		let str = JSON.stringify(massive);
		formData.append('massive', str )



		addRasclad(formData).then(data=>{

			if(data.status){
				toast.success(data.message)
				setShow(false)
				setShow2(false)
				getRasFunc()
			}else{
				toast.error(data.message)
			}
		})
	}

	const delRasFunc = (id) => {
		delRasclad(id).then(data=>{
			if(data.status){
				setRasclad(rasclad.filter(f => f.id !== id))
			}
		})
	}

	const UnsafeComponent =({ html }) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />;
	}

	useEffect(() => {
		izmDanie()
	}, [active]);

	const  izmDanie = () =>{

		if(rasclad){
			setName(rasclad[active].name)
			setText(rasclad[active].text)
			setMassive(rasclad[active].danie)
			//setShow(true)
		}

	}



	return (
		<div>
			<h3>Редактирование расклада</h3>
			<Button variant="outline-light" className="mb-3"
				onClick={()=>setShow2(true)}
			>Добавить</Button>

			<Row>
				{rasclad && rasclad.map((r,index) =>
					<Col key={index} md={4} className="my-3">
						<Card>
							<Card.Header>{r.name}</Card.Header>
							<Card.Body>
								<Card.Text>
									<UnsafeComponent html={r.text} />
								</Card.Text>
							</Card.Body>
							<Card.Footer className="d-flex justify-content-between">

									<Button onClick={() => {
										setActive(index)
										setShow(true)
									}} variant="warning">Изменить</Button>
									<Button variant="danger" onClick={() => delRasFunc(r.id)}>Удалить</Button>

							</Card.Footer>


						</Card>
					</Col>
				)
				}
			</Row>


			<Modal size="lg" show={show} onHide={() => setShow(false)} animation={false} className="text-black">
				<Modal.Header closeButton>
					<Modal.Title>Изменить</Modal.Title>
				</Modal.Header>
				<Modal.Body>


					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Название</Form.Label>
						<Form.Control type="text" placeholder="Имя расклада"
													value={name}
													onChange={(e)=>setName(e.target.value)}
						/>

					</Form.Group>

					<ReactQuill
						value={text}
						onChange={(value) => setText(value)}
						modules={{
							toolbar: [
								['bold', 'italic', 'underline', 'strike'],        // toggled buttons
								['blockquote', 'code-block'],

								[{ 'header': 1 }, { 'header': 2 }],               // custom button values
								[{ 'list': 'ordered'}, { 'list': 'bullet' }],
								[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
								[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
								[{ 'direction': 'rtl' }],                         // text direction

								[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
								[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

								[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
								[{ 'font': [] }],
								[{ 'align': [] }],

								['clean']
							]
						}}
						theme="snow"
					/>


					<Clicker col={col} row={row} setCol={setCol} setRow={setRow}/>


					{massive.length > 0 &&
						massive.map((value, index) => (
							<Row key={index}>
								{value.map((s,ind) => (
									<Col key={ind}>
										<Form.Control
											type="number"
											size="lg"
											onChange={(e) => {
												funcIzm(index,ind, e.target.value)
											}
											}
											value={s}   />
									</Col>
								))}
							</Row>
						))}
				</Modal.Body>
				<Modal.Footer>

					<Button variant="primary" onClick={() => saveRasklad(rasclad[active].id)} >
						Изменить
					</Button>
				</Modal.Footer>
			</Modal>





			<Modal size="lg" show={show2} onHide={()=>setShow2(false)} animation={false} className="text-black">
				<Modal.Header closeButton>
					<Modal.Title>Добавить</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<Form.Group className="mb-3" controlId="formBasicEmail">
						<Form.Label>Название</Form.Label>
						<Form.Control type="text" placeholder="Имя расклада"
							value={name}
							onChange={(e)=>setName(e.target.value)}

						/>

					</Form.Group>




					<ReactQuill
						value={text}
						onChange={(value) => setText(value)}
						modules={{
							toolbar: [
								['bold', 'italic', 'underline', 'strike'],        // toggled buttons
								['blockquote', 'code-block'],

								[{ 'header': 1 }, { 'header': 2 }],               // custom button values
								[{ 'list': 'ordered'}, { 'list': 'bullet' }],
								[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
								[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
								[{ 'direction': 'rtl' }],                         // text direction

								[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
								[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

								[{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
								[{ 'font': [] }],
								[{ 'align': [] }],

								['clean']
							]
						}}
						theme="snow"
					/>


					<Clicker col={col} row={row} setCol={setCol} setRow={setRow}/>




					{massive.length > 0 &&
						massive.map((value, index) => (
						<Row key={index}>
							{value.map((s,ind) => (
								<Col key={ind}>
									<Form.Control
										type="number"
										size="lg"
													onChange={(e) => {
														funcIzm(index,ind, e.target.value)
													}
												}
											value={s}   />
								</Col>
							))}
						</Row>
					))}
				</Modal.Body>
				<Modal.Footer>

					<Button variant="primary" onClick={() => saveRasklad(0)} >
						Сохранить
					</Button>
				</Modal.Footer>
			</Modal>

		</div>
	);
};

export default RascladEdit;

