import React, {useState} from 'react';
import Admin from "../layout/Admin";
import RascladEdit from "./RascladEdit";
import {ToastContainer} from "react-toastify";
import Matrica from "./Matrica";
import {Nav} from "react-bootstrap";

const Uprava = () => {

	const [pages, setPages] = useState(2)
	return (
		<Admin>
			<Nav variant="tabs" className="my-3" >
				<Nav.Item>
					<Nav.Link className="text-white" onClick={()=>setPages(1)}>Рассклады</Nav.Link>
				</Nav.Item>
				<Nav.Item>
					<Nav.Link className="text-white"  onClick={()=>setPages(2)}>Матрица</Nav.Link>
				</Nav.Item>
			</Nav>
			{pages === 1 ? <RascladEdit/> :  null}
			{pages === 2 ?  <Matrica/> :  null}
			<ToastContainer/>
		</Admin>
	);
};

export default Uprava;