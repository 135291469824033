
import React from "react";
import Uprava from "./pages/uprava/Uprava";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";
import NotFound from "./pages/NotFound";
import NavBar from "./pages/block/NavBar";
import Footer from "./pages/block/Footer";
function App() {
  return (
    <BrowserRouter>
      <div className="osnPage">
        <NavBar/>
        <Routes >
          <Route path='/' element={<Main/>}/>
          <Route path='/uprava' element={<Uprava/>}/>
          <Route path='*' element={<NotFound/>} />
        </Routes>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
